<template>
	<v-row>
		<BottomNavigationContainer v-if="menu.length > 1" :menu="menu" />

		<v-col cols="12" class="d-none d-md-block">
			<ToolbarContainer :menu="menu" />
			<v-toolbar elevation="1" height="25" rounded="b">
				<Breadcrumbs />
			</v-toolbar>
		</v-col>

		<v-col cols="12" lg="12">
			<router-view />
		</v-col>
	</v-row>
</template>

<script>
import Breadcrumbs from "../Widgets/Breadcrumbs";
import ToolbarContainer from "../Widgets/ToolbarContainer";
import BottomNavigationContainer from "../Widgets/BottomNavigationContainer";

export default {
	name: "RemissaoContainer",
	components: { Breadcrumbs, ToolbarContainer, BottomNavigationContainer },
	data: () => ({
		menu: [],
		drawer: false,
		group: null,
		value: 0,
	}),
	methods: {
		montarMenu() {
			let m = [];

			if (this.permissao("remissao_listar")) {
				m.push({
					titulo: "Remissões de vendas",
					titulo_app: "Remissões",
					icon: "mdi-shopping-outline",
					to: "/remissao",
					tooltip: "Remissões de vendas",
				});
			}

			this.menu = m;
		},
	},
	mounted() {
		this.montarMenu();
	},
};
</script>

<style scoped>
</style>